<template>
  <div class="wujieBox">
    <WujieVue
      width="100%"
      height="100%"
      name="datahub"
      :url="microUrl"
      :props="{ jump }"
    ></WujieVue>
    <div
      class="wujie-loading"
      v-loading="loading"
      v-if="loading"
      element-loading-background="rgba(247,247,247,.7)"
    ></div>
  </div>
</template>

<script>
  import { bus } from 'wujie';

  const env = process.env.VUE_APP_NODE_ENV; // 当前部署环境

  export default {
    data() {
      return {
        microUrl: '', // 子应用地址
        loading: true
      };
    },
    watch: {
      $route: {
        handler(val) {
          console.log('----主应用路由更改----  :', val);
          console.log('----当前node环境标识----  :', env);
          const mainUrl = val.fullPath; // 主应用的url
          console.log('----主应用路由更改----  :', val);
          const pathFlag = '/datahub';
          // const pathFlag = '/micro/datahub';
          const microPath = '/bdp-saas-bi';
          const origin = window.location.origin;
          this.microUrl = `${origin}/v3${mainUrl.replace(pathFlag, microPath)}`;
          if (env.startsWith('dev_test')) {
            const devDomain = 'http://localhost:8000';
            const strIndex = mainUrl.indexOf(pathFlag);
            this.microUrl = `${devDomain}${val.fullPath.substring(strIndex + pathFlag.length)}`;
          }
          console.log('----转换后------  datahub子应用地址:', this.microUrl);
        },
        immediate: true
      }
    },
    methods: {
      initBusEvent() {
        bus.$on('datahub-sub-app-loaded', () => {
          this.loading = false; // true 显示loading, false 隐藏loading
        });
      },
      jump(options = {}) {
        this.$router.push(options);
      }
    },
    created() {
      this.initBusEvent();
    }
  };
</script>
<style lang="less">
  .wujieBox {
    width: 100%;
    height: 100%;
    position: relative;

    .wujie-loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  }
</style>
